import {
  SET_LOGIN_STATUS,
  SET_USERINFO,
  SET_AGENT_ID,
} from "./mutation-types";

// 设置登录状态
export const setLoginAction = ({ commit }, loginAction = {}) => {
  console.log(111, loginAction)
  commit(SET_LOGIN_STATUS, loginAction.loginStatus);
  commit(SET_USERINFO, loginAction.userInfo);
  commit(SET_AGENT_ID, loginAction.agentId);
};
