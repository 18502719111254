<template>
  <div class="pages">
    <div class="query-wrap">
      <van-row type="flex">
        <van-col class="col" :class="{ active: active === 0 }" span="7" @click="onChange(0)">今日</van-col>
        <van-col class="col" :class="{ active: active === 1 }" span="7" @click="onChange(1)">昨日</van-col>
        <van-col class="col" :class="{ active: active === 2 }" span="7" @click="onChange(2)">近7日</van-col>
      </van-row>
      <van-icon name="calendar-o" size="2rem" @click="show = true" />
    </div>
    <div style="margin-top: 20px" class="t-table_border">
      <table class="t-table_th_round">
        <tr>
          <th v-for="(item, index) in params"
            :style="{
              width: 'auto',
              height: '31px',
              background: '#ECF5FF',
              color: '#868C94',
              textAlign: 'center',
              whiteSpace: 'nowrap'
            }"
            :key="index+'p'"
          >
            <span :style="{whiteSpace: 'nowrap'}">{{ item.title }}</span>
          </th>
        </tr>
        <tr
          v-show="list && list.length > 0"
          v-for="(item, index) in list"
          :key="index+'p1'"
          :style="{
            background: rowBgColor && (index%2 !== 0 ? rowBgColor : '#fff'),
          }"
        >
          <td
            v-for="(ite, idx) in params"
            :key="idx+'p2'"
            :style="{
              textAlign: align,
              wordBreak: scroll ? '' : 'break-word',
            }"
          >
            <span v-if="wordWrap && wordWrap === ite.labelKey" style="white-space: nowrap">{{
              item[ite.labelKey]
            }}</span>
            <span v-else>{{ item[ite.labelKey] }}</span>
          </td>
        </tr>
        <tr v-if="list.length === 0">
          <td :colspan="params.length">
            <slot name="empty">
              <div style="color: #666; font-size: 14px; padding: 6px; text-align: center">暂无数据</div>
            </slot>
          </td>
        </tr>
      </table>
    </div>

    <van-calendar v-model="show" type="range" :min-date="minDate" :max-range="90" color="#1989fa" @confirm="onConfirm" />
  </div>
</template>

<script>
import axios from "axios";
import { Toast } from "vant";

export default {
  name: "secondData",
  props: {
    id: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      active: 0,
      show: false,
      borderColor: '#D4E6FF',
      hBgColor: '#EDF5FF',
      hColor: '#868C94',
      rowHeight: '31px',
      rowBgColor: '#fff',
      align: 'center',
      wordWrap: null,
      params: [
        {
          title: '姓名',
          labelKey: 'partnerName'
        },
        {
          title: '推广用户',
          labelKey: 'userNum'
        },
        {
          title: '推广订单数',
          labelKey: 'orderNum'
        },
        {
          title: '推广金额',
          labelKey: 'amount'
        },
      ],
      list: [],
      scroll: false,
      minDate: new Date(1900, 1, 1,0,0,0),
      startTime: this.$moment().format("YYYY-MM-DD 00:00:00"),
      endTime: this.$moment().format("YYYY-MM-DD 23:59:59"),
    }
  },
  methods: {
    getData() {
      axios({
        url: `https://api.ybrsmfw.cn/amb/admin/agent/promoter/${this.id}`,
        method: "post",
        data: {
          id: this.id,
          startTime: this.startTime,
          endTime: this.endTime,
        }
      }).then(res => {
        if (res.data.success) {
          this.list = res.data.result;
        } else {
          Toast(res.data.message);
        }
      })
    },
    onChange(index) {
      if (index === 0) {
        this.startTime = this.$moment().format("YYYY-MM-DD 00:00:00");
        this.endTime = this.$moment().format("YYYY-MM-DD 23:59:59");
      } else if (index === 1) {
        this.startTime = this.$moment().subtract(1, 'days').format("YYYY-MM-DD 00:00:00");
        this.endTime = this.$moment().subtract(1, 'days').format("YYYY-MM-DD 23:59:59");
      } else if (index === 2) {
        this.startTime = this.$moment().subtract(7, 'days').format("YYYY-MM-DD 00:00:00");
        this.endTime = this.$moment().format("YYYY-MM-DD 23:59:59");
      }
      console.log(this.startTime, this.endTime)
      this.active = index;
      this.getData();
    },
    formatDate(date) {
      if (date) {
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        return year + "-" + (Number(month) < 10 ? "0" + month : month) + "-" + (Number(day) < 10 ? "0" + day : day);
      }
    },
    onConfirm(date) {
      console.log(11, date)
      const [start, end] = date;
      this.show = false;
      this.active = 3;
      this.startTime = this.formatDate(start) + " 00:00:00";
      this.endTime = this.formatDate(end) + " 23:59:59";
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.pages {
  width: 100%;
}
.query-wrap {
  display: flex;
  align-items: center;
}
.van-row {
  width: 100%;
}
.van-col {
  height: 30px;
  line-height: 30px;
  &.active {
    background-color: #1989fa;
    border-color: #1989fa;
    color: #FFFFFF
  }
}
.col {
  border: 1px solid #323233;
  &:nth-of-type(1) {
    border-right: none;
    border-radius: 6px 0 0 6px;
  }
  &:nth-of-type(2) {
    border-right: none;
  }
  &:nth-of-type(3) {
    border-radius: 0 6px 6px 0
  }
}
table {
  width: 100%;
  margin: auto;
  border-spacing: 0;
  font-size: 14px;
}

.t-table_border {
  table {
    width: 100%;
    margin: auto;
    border-spacing: 0;
    font-size: 14px;
  }

  table tr > th {
    text-align: center;
    border: 1px solid #D4E6FF;
    padding: 4px;
  }

  table tr > th:nth-child(2n) {
    border-right: none;
    border-left: none;
  }

  table tr > th:last-child {
    border-right: 1px solid #D4E6FF;
  }

  table tr > td {
    text-align: center;
    border: 1px solid #D4E6FF;
    border-top: none;
    padding: 4px;
  }

  table tr > td:nth-child(2n) {
    border-right: none;
    border-left: none;
  }

  table tr > td:last-child {
    border-right: 1px solid #D4E6FF;
  }
}

.t-table_th_round {
  tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }

  tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }

  tr:last-child {
    td:first-child {
      border-bottom-left-radius: 8px;
    }
  }

  tr:last-child {
    td:last-child {
      border-bottom-right-radius: 8px;
    }
  }
}
</style>
