<template>
  <div class="home">
    <template v-if="userInfo.parentId && userInfo.parentId === '0'">
      <div class="content-wrap" v-if="active < 2">
        <home-data ref="homeData" v-if="active === 0" :id="userInfo.id" />
        <second-data ref="secondData" v-if="active === 1" :id="userInfo.id" />
      </div>
      <div v-if="active === 2" style="display: block">
        <my />
      </div>
    </template>
    <template v-else>
      <div class="content-wrap" v-if="active === 0">
        <home-data ref="homeData" v-if="active === 0" :id="userInfo.id" />
      </div>
      <div v-if="active === 1" style="display: block">
        <my />
      </div>
    </template>
    <van-tabbar v-model="active" @change="onChange">
      <van-tabbar-item icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item icon="orders-o" v-if="userInfo.parentId && userInfo.parentId === '0'">推广人</van-tabbar-item>
      <van-tabbar-item icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
// @ is an alias to /src

import HomeData from "./components/homeData";
import SecondData from "./components/secondData";
import My from "./components/my";
export default {
  name: "Home",
  components: {
    My,
    SecondData,
    HomeData
  },
  data() {
    return {
      active: 0,
      icon: {
        home: "../assets/image/tabBar/home.png",
        homeActive: "../image/home-selected.png",
      },
      agentId: null,
      userInfo: null,
    };
  },
  created() {
    if (!localStorage.getItem("loginStatus") || localStorage.getItem("loginStatus") !== 'true') {
      this.$router.push("/login");
    } else {
      this.agentId = localStorage.getItem("agentId");
      this.userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {};
      this.$nextTick(() => {
        this.$refs.homeData.getData();
      });
    }
  },
  methods: {
    onChange(index) {
      this.active = index;
      if (index === 0) {
        this.$nextTick(() => {
          this.$refs.homeData.getData();
        });
      } else if (index === 1) {
        this.$nextTick(() => {
          this.$refs.secondData.getData();
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: calc(100vh - 50px);
  background-color: #F7F8FA;
  padding: 0 24px;
  box-sizing: border-box;
}
.content-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.top-wrap {
  width: 100%;
  height: 30px;
  //display: flex;
  //align-items: center;
  //justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 24px;
  .van-col {
    border: 1px solid;
    height: 30px;
    line-height: 30px;
  }
  .tab-wrap {
    width: 80%;
    height: 100%;
    display: flex;
    border: 1px solid;
    .tab-item {
      width: auto;
      height: 100%;
      border-right: 1px solid;
    }
  }
}
:deep .van-cell-group {
  //width: 100%;
}
</style>
