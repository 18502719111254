<template>
  <div class="pages">
    <div class="query-wrap">
      <van-row type="flex">
        <van-col class="col" :class="{ active: active === 0 }" span="7" @click="onChange(0)">今日</van-col>
        <van-col class="col" :class="{ active: active === 1 }" span="7" @click="onChange(1)">昨日</van-col>
        <van-col class="col" :class="{ active: active === 2 }" span="7" @click="onChange(2)">近7日</van-col>
      </van-row>
      <van-icon name="calendar-o" size="2rem" @click="show = true" />
    </div>
    <div style="margin-top: 20px">
      <van-cell-group>
        <van-cell title="推广用户数" :value="dataObj.userNum || 0" />
        <van-cell title="推广订单数" :value="dataObj.orderNum || 0" />
        <van-cell title="推广订单金额" :value="dataObj.amount || 0" />
        <van-cell title="入账金额" :value="dataObj.balance || 0" />
      </van-cell-group>
    </div>

    <van-calendar v-model="show" type="range" :min-date="minDate" :max-range="90" color="#1989fa" @confirm="onConfirm" />
  </div>
</template>

<script>
import axios from "axios";
import { Toast } from "vant";

export default {
  name: "homeData",
  props: {
    id: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      active: 0,
      show: false,
      minDate: new Date(1900, 1, 1,0,0,0),
      startTime: this.$moment().format("YYYY-MM-DD 00:00:00"),
      endTime: this.$moment().format("YYYY-MM-DD 23:59:59"),
      dataObj: {},
    }
  },
  methods: {
    getData() {
      axios({
        url: "https://api.ybrsmfw.cn/amb/admin/agent/data",
        method: "post",
        data: {
          id: this.id,
          startTime: this.startTime,
          endTime: this.endTime,
        }
      }).then(res => {
        if (res.data.success) {
          this.dataObj = res.data.result;
        } else {
          Toast(res.data.message);
        }
      })
    },
    onChange(index) {
      if (index === 0) {
        this.startTime = this.$moment().format("YYYY-MM-DD 00:00:00");
        this.endTime = this.$moment().format("YYYY-MM-DD 23:59:59");
      } else if (index === 1) {
        this.startTime = this.$moment().subtract(1, 'days').format("YYYY-MM-DD 00:00:00");
        this.endTime = this.$moment().subtract(1, 'days').format("YYYY-MM-DD 23:59:59");
      } else if (index === 2) {
        this.startTime = this.$moment().subtract(7, 'days').format("YYYY-MM-DD 00:00:00");
        this.endTime = this.$moment().format("YYYY-MM-DD 23:59:59");
      }
      console.log(this.startTime, this.endTime)
      this.active = index;
      this.getData();
    },
    formatDate(date) {
      if (date) {
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        return year + "-" + (Number(month) < 10 ? "0" + month : month) + "-" + (Number(day) < 10 ? "0" + day : day);
      }
    },
    onConfirm(date) {
      console.log(11, date)
      const [start, end] = date;
      this.show = false;
      this.active = 3;
      this.startTime = this.formatDate(start) + " 00:00:00";
      this.endTime = this.formatDate(end) + " 23:59:59";
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.pages {
  width: 100%;
}
.query-wrap {
  display: flex;
  align-items: center;
}
.van-row {
  width: 100%;
}
.van-col {
  height: 30px;
  line-height: 30px;
  &.active {
    background-color: #1989fa;
    border-color: #1989fa;
    color: #FFFFFF
  }
}
.col {
  border: 1px solid #323233;
  &:nth-of-type(1) {
    border-right: none;
    border-radius: 6px 0 0 6px;
  }
  &:nth-of-type(2) {
    border-right: none;
  }
  &:nth-of-type(3) {
    border-radius: 0 6px 6px 0
  }
}
</style>
