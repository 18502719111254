var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pages"},[_c('div',{staticClass:"query-wrap"},[_c('van-row',{attrs:{"type":"flex"}},[_c('van-col',{staticClass:"col",class:{ active: _vm.active === 0 },attrs:{"span":"7"},on:{"click":function($event){return _vm.onChange(0)}}},[_vm._v("今日")]),_c('van-col',{staticClass:"col",class:{ active: _vm.active === 1 },attrs:{"span":"7"},on:{"click":function($event){return _vm.onChange(1)}}},[_vm._v("昨日")]),_c('van-col',{staticClass:"col",class:{ active: _vm.active === 2 },attrs:{"span":"7"},on:{"click":function($event){return _vm.onChange(2)}}},[_vm._v("近7日")])],1),_c('van-icon',{attrs:{"name":"calendar-o","size":"2rem"},on:{"click":function($event){_vm.show = true}}})],1),_c('div',{staticClass:"t-table_border",staticStyle:{"margin-top":"20px"}},[_c('table',{staticClass:"t-table_th_round"},[_c('tr',_vm._l((_vm.params),function(item,index){return _c('th',{key:index+'p',style:({
            width: 'auto',
            height: '31px',
            background: '#ECF5FF',
            color: '#868C94',
            textAlign: 'center',
            whiteSpace: 'nowrap'
          })},[_c('span',{style:({whiteSpace: 'nowrap'})},[_vm._v(_vm._s(item.title))])])}),0),_vm._l((_vm.list),function(item,index){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.list && _vm.list.length > 0),expression:"list && list.length > 0"}],key:index+'p1',style:({
          background: _vm.rowBgColor && (index%2 !== 0 ? _vm.rowBgColor : '#fff'),
        })},_vm._l((_vm.params),function(ite,idx){return _c('td',{key:idx+'p2',style:({
            textAlign: _vm.align,
            wordBreak: _vm.scroll ? '' : 'break-word',
          })},[(_vm.wordWrap && _vm.wordWrap === ite.labelKey)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item[ite.labelKey]))]):_c('span',[_vm._v(_vm._s(item[ite.labelKey]))])])}),0)}),(_vm.list.length === 0)?_c('tr',[_c('td',{attrs:{"colspan":_vm.params.length}},[_vm._t("empty",function(){return [_c('div',{staticStyle:{"color":"#666","font-size":"14px","padding":"6px","text-align":"center"}},[_vm._v("暂无数据")])]})],2)]):_vm._e()],2)]),_c('van-calendar',{attrs:{"type":"range","min-date":_vm.minDate,"max-range":90,"color":"#1989fa"},on:{"confirm":_vm.onConfirm},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }