import * as types from "./mutation-types";

export default {
  /**
   * 登录状态
   */
  [types.SET_LOGIN_STATUS](state, loginStatus) {
    state.isLogin = loginStatus;
  },
  /**
   * 用户信息
   */
  [types.SET_USERINFO](state, userInfo) {
    state.userInfo = userInfo;
  },
  /**
   * 用户有无优惠券
   */
  [types.SET_AGENT_ID](state, agentId) {
    state.agentId = agentId;
  },
};
