<template>
  <div class="pages">
    <van-nav-bar
      :title="userInfo.partnerName"
      :left-arrow="false"
      fixed
      placeholder
    />
    <div class="list-wrap" style="margin-top: 20px">
      <div class="list-item">
        <span class="item-name">推广二维码:</span>
        <div class="item-right">
          <img class="img" :src="userInfo.qrCode">
          <van-button class="btn btn-save" type="primary" @click="saveImg">长按图片保存</van-button>
        </div>
      </div>
      <van-divider />
      <div class="list-item">
        <span class="item-name">推广链接:</span>
        <div class="item-right">
          <div class="href">
            <span>{{ userInfo.link }}</span>
          </div>
          <van-button class="btn copy" type="primary" @click="copyUrl">复制</van-button>
        </div>
      </div>
      <van-divider />
      <div class="list-item">
        <span class="item-name">密码:</span>
        <div class="item-right">
          <div class="href">
            <span>******</span>
          </div>
          <van-button class="btn btn-pwd" type="primary" @click="onResetPwd">修改密码</van-button>
        </div>
      </div>
    </div>

    <van-popup v-model="show" title="修改密码" :style="{ width: '70%'  }" round @cancel="onCancel" @confirm="onSubmit">
      <van-cell-group style="margin-top: 10px">
        <van-field v-model="value" label="旧密码" type="password" placeholder="请输入旧密码" />
        <van-field v-model="value1" label="新密码" type="password" maxlength="8" placeholder="新密码为6~8位" />
        <van-field v-model="value2" label="确认密码" type="password" maxlength="8" placeholder="请输入确认密码" />
      </van-cell-group>
      <div class="btn-wrap">
        <van-button class="btn btn-cancel" @click="onCancel">取消</van-button>
        <van-button class="btn btn-ok" type="primary" @click="onSubmit">确定</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import Clipboard from "clipboard";
import axios from "axios";

export default {
  name: "my",
  data() {
    return {
      qrCode: null,
      link: null,
      show: false,
      value: null,
      value1: null,
      value2: null,
      userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
    }
  },
  methods: {
    onResetPwd() {
      this.show = true;
    },
    onCancel() {
      this.show = false;
    },
    onSubmit() {
      this.show = true;
      if (!this.value) {
        Toast('请输入旧密码');
        return true;
      }
      if (!this.value1) {
        Toast('请输入新密码');
        return true;
      }
      if (!this.value2) {
        Toast('请输入确认密码');
        return true;
      }
      if (this.value1 !== this.value2) {
        Toast('两次输入的密码不一致');
        return true;
      }
      axios({
        url: "https://api.ybrsmfw.cn/amb/admin/agent/update",
        method: "post",
        data: {
          id: this.userInfo.id,
          password: this.value2,
        }
      }).then(res => {
        if (res.data.success) {
          Toast("密码已修改，请重新登录");
          localStorage.setItem("loginStatus", false);
          localStorage.setItem("agentId", null);
          localStorage.setItem("userInfo", '{}');
          this.$router.push("/login");
        } else {
          Toast(res.data.message);
        }
      })
    },
    saveImg() {
      this.savePic(this.userInfo.qrCode);
    },
    savePic(Url) {
      // Url = this.qrCode //图片路径，也可以传值进来
      // var triggerEvent = "touchstart"; //指定下载方式
      var blob = new Blob([''], { type: 'application/octet-stream' }); //二进制大型对象blob
      var url = URL.createObjectURL(blob); //创建一个字符串路径空位
      var a = document.createElement('a'); //创建一个 a 标签
      a.href = Url;  //把路径赋到a标签的href上
      //正则表达式，这里是把图片文件名分离出来。拿到文件名赋到a.download,作为文件名来使用文本
      a.download = Url.replace(/(.*\/)*([^.]+.*)/ig, "$2").split("?")[0];
      /* var e = document.createEvent('MouseEvents');  //创建事件（MouseEvents鼠标事件）
     e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null); //初始化鼠标事件（initMouseEvent已弃用）*/
      //代替方法。创建鼠标事件并初始化（后面这些参数我也不清楚，参考文档吧https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/MouseEvent）
      var e = new MouseEvent('click', (true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null));
      //派遣后，它将不再执行任何操作。执行保存到本地
      a.dispatchEvent(e);
      //释放一个已经存在的路径（有创建createObjectURL就要释放revokeObjectURL）
      URL.revokeObjectURL(url);
    },
    copyUrl() {
      let copy = this.userInfo.link;  //要复制的链接
      let clipboard = new Clipboard('.copy', {  //绑定需要的触发的dom
        text: function () {
          return copy;
        }
      });
      clipboard.on('success', function () {
        Toast("链接复制成功");
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.pages {
  width: 100%;
}
.list-wrap {
  //width: 100%;
  flex: 1;
  height: 200px;
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 10px;
  .list-item {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item-name {

    }
    .item-right {
      width: 75%;
      display: flex;
      align-items: center;
      justify-content: right;
      .img {
        width: 60px;
        height: 60px;
      }
      .href {
        width: 70%;
      }
      .btn {
        width: 70px;
        height: 30px;
        margin-left: 10px;
      }
      .btn-pwd {
        width: 100px;
      }
      .btn-save {
        width: 120px;
      }
    }
  }
}
.btn-wrap {
  width: 100%;
  height: 40px;
  margin-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .btn {
    width: 80px;
    height: 35px;
    border-radius: 16px;
  }
  .btn-cancel {
    margin-right: 10px;
  }
}
</style>
