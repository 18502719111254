import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from 'moment';
// 引入 vant ui
import {
  Button,
  Tabbar,
  TabbarItem,
  Cell,
  CellGroup,
  Col,
  Row,
  Icon,
  Calendar,
  NavBar,
  Divider,
  Popup,
  Field,
  Toast,
} from "vant";
import 'vant/lib/index.css';

//挂载到原型
Vue.prototype.$moment = moment ;
//汉化，否则显示日期格式是国外的日期格式
moment.locale("zh-CN");

Vue.config.productionTip = false;

Vue.use(Button);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(Calendar);
Vue.use(NavBar);
Vue.use(Divider);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Toast);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
